import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import Cookies from "js-cookie";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import create1 from "../../../../Image/delcato1.png";
import { InputAdornment } from "@material-ui/core";
import { AccountCircle, LockOpen } from "@material-ui/icons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { HOST } from "../../../../container/dr_examination/Redux/DoctorConstant";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    const { intl } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .min(4, "Minimum 4 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),

        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "#1B96BC !important",
                    },
                },
            },
        },
    });
    // const getInputClasses = (fieldname) => {
    //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //     return "is-invalid";
    //   }

    //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //     return "is-valid";
    //   }

    //   return "";
    // };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            enableLoading();

            // setTimeout(() => {
            login(values.email.toLowerCase(), values.password, {
                withCredentials: true,
                credentials: "include",
            })
                .then(({ data: { token, user, session_id, section_no } }) => {
                    disableLoading();
                    props.login(user, token);
                    if (HOST === "http://localhost:8080") {
                        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true });
                        Cookies.set("userId", user.id, { sameSite: "none", secure: true });
                        Cookies.set("userRole", user.role, { sameSite: "none", secure: true });
                        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true });
                        Cookies.set("session_id", session_id, { sameSite: "none", secure: true });
                        Cookies.set("section_no", section_no, { sameSite: "none", secure: true });
                        Cookies.set("refreshToken", token.refreshToken);
                    } else if (HOST === "https://ap1.imeapp.in") {
                        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
                    } else if (HOST === "https://api.delicatoimeapp.com") {
                        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
                    } else if (HOST === "https://api.assesseaseime.com") {
                        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
                    } else if (HOST === "https://api.nyimeapp.com") {
                        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".nyimeapp.com", path: "/" });
                        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".nyimeapp.com" });
                    }
                })
                .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(err.response?.data?.err || "null");
                });
        },
    });

    return (
        <div>
            {/* begin::Head */}
            <div className="text-center" style={{ marginBottom: "-35px" }}>
                <img alt="paris" src={create1} style={{ height: "100px", width: "120px", marginLeft: "40px", marginBottom: "2px" }} />
                <br></br>
                <p style={{ marginLeft: "40px", fontWeight: "500" }}>Enter your username and password</p>
            </div>
            <form onSubmit={formik.handleSubmit} className="form  fv-plugins-framework" style={{ width: "500px", marginLeft: "20%" }}>
                {formik.status ? (
                    // <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    //   <div className="alert-text font-weight-bold">Not valid email address</div>
                    // </div>
                    <div className="mt-18 alert alert-custom alert-light-danger" style={{ padding: "4px", paddingLeft: "20px", width: "70%" }}>
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                ) : (
                    <div className="mb-10 alert">
                        {/*<div className="alert-text ">
               Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div> */}
                    </div>
                )}
                <div>
                    <span className="d-flex">
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="outlined-basic "
                                label="username"
                                type="text"
                                style={{ width: "70%" }}
                                fullWidth
                                margin="normal"
                                placeholder="Enter User Name"
                                className="emailinput"
                                autoFocus
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                name="email"
                                variant="outlined"
                                {...formik.getFieldProps("email")}
                            />
                        </ThemeProvider>
                        {formik.touched.email && formik.errors.email ? (
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlayClassName="custom-overlay"
                                overlay={<Tooltip id="tooltip-top">not a valid username address</Tooltip>}
                            >
                                <Button style={{ border: "none", background: "none" }}>
                                    <i class="fa-solid fa-circle-exclamation" style={{ color: "#DE3030", position: "relative", marginLeft: "3px" }}></i>
                                </Button>
                            </OverlayTrigger>
                        ) : null}
                    </span>
                </div>

                <div>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block" style={{ color: "#DE3030" }}>
                                username required
                            </div>
                        </div>
                    ) : null}
                    <div>
                        <span className="d-flex" style={{ marginTop: "10px" }}>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    id="outlined-basic"
                                    label="password"
                                    fullWidth
                                    margin="normal"
                                    placeholder="Enter password"
                                    autoComplete="current-password"
                                    style={{ width: "70%" }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOpen />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <button type="button" style={{ border: "none" }} className="btn btn-outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                                </button>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...formik.getFieldProps("password")}
                                    name="password"
                                    variant="outlined"
                                    type={showPassword ? "text" : "password"}
                                />
                            </ThemeProvider>
                            {formik.touched.password && formik.errors.password ? (
                                <OverlayTrigger key="top" placement="top" overlayClassName="custom-overlay" overlay={<Tooltip id="tooltip-top">wrong password</Tooltip>}>
                                    <Button style={{ border: "none", background: "none" }}>
                                        <i class="fa-solid fa-circle-exclamation" style={{ color: "#DE3030", position: "relative", marginLeft: "3px" }}></i>
                                    </Button>
                                </OverlayTrigger>
                            ) : null}
                        </span>
                    </div>
                    <div style={{ marginLeft: "250px" }}>
                        <Link to="/auth/forgot-password" className="text-dark text-hover-primary my-3 mr-2" id="kt_login_forgot">
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                        </Link>
                    </div>

                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block" style={{ color: "#DE3030" }}>
                                password required
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        style={{ backgroundColor: "#EF205F", border: "none" }}
                        disabled={formik.isSubmitting}
                        //  className="sign1 btn btn-primary font-weight-bold px-9 py-4 my-3"
                        className="sign1 btn btn-primary font-weight-bold px-9 py-4 my-3"
                    >
                        <span>Sign In</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>

            {/*end::Form*/}

            <h3 style={{ textAlign: "center", letterSpacing: "25px", marginTop: "70px", marginLeft: "70px" }}>WELCOME</h3>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
