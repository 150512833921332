import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import Cookies from "js-cookie";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    // logout();
    setTimeout(() => {
      Cookies.remove("accessToken");
      Cookies.remove("userRole");
      Cookies.remove("userId");
      Cookies.remove("userTypeId");
      Cookies.remove("refreshToken");
      Cookies.remove("session_id");
      Cookies.remove("section_no");
    }, 30);
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.token) }), auth.actions)(Logout);
