/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import user from "../../../../Image/sign in.png"
export function QuickUserToggler() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
      
          <div
            className="btn btn-icon btn-dark-dark
            
            btn-lg w-40px h-40px"
            id="kt_quick_user_toggle"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            style={{
              zIndex: '1002', 
              position: 'absolute'
            }}
          >
            <span className="btn font-weight-bold" >
              <img src={user} width={"60px"} style={{marginTop:"50px",height:"57px"}} />
            </span>
          </div>
       
      )}

      {/* {!layoutProps.offcanvas && <UserProfileDropdown />} */}
    </>
  );
}
